<template>
  <div class="page my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <NodeList3 />
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NodeList3 from "./components/NodeList3.vue"
  export default {
    components: {
      NodeList3
    },   
  }
</script>

<style lang="scss" scoped></style>
